import React from 'react';
import { createUUID } from '../react-flow-pedigree/utils';
const ResultFilterColumn = (props) => {
  return (
    <li
      id={props.item.order}
      onDragOver={props.onDragOver}
      onDrop={props.onDrop}
      draggable='true'
      onDrag={props.onDrag}
      >
      <a href="#" draggable='false' className="  pull-left min-width--inherit">
        <svg className="list-icon" xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" viewBox="0 0 180 600">
          <path d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"/>
        </svg>
      </a>
      <label className="checkbox-inline">
        <input
          key={createUUID()}
          onChange={(e) => props.onChecked(e.target.checked)}
          type="checkbox" value={props.item.value} checked={props.item.checked}
          className={`result-filter-column-${props.item.value}`} />
           <label className="cursor-edit-column edit-column-label">
           {props.item.value == 'sex_at_birth' ? 'Sex Assigned at Birth' : props.item.label}
           </label>
      </label>
        <hr style={{margin: "5px"}}></hr>
      </li>
    );
}

export default ResultFilterColumn
