export const document = {
  name: '',
  type: null,
  created_at: null,
  note: '',
  file: null
}

export const risk_settings = {
  boadicea: false,
  claus: false,
  gail: false,
  tyrer_cuzick: false,
  competing_mortality: false,
  premm: false,
  boadicea_incidence_rates: 'USA',
  user: null,
  brcapro: false,
  mmrpro: false,
  pancpro: false,
  melapro: false,
  qrisk3: false,
  prs_breast: null,
  prs_ovarian: null,
  zscore_breast: null,
  zscore_ovarian: null,
  backdate_age: null
}

export const risk_results = {
  boadicea: {
    breast: {fiveYear: 'N/A', lifetime: 'N/A'},
    ovarian: {fiveYear: 'N/A', lifetime: 'N/A'},
    cancer_risks: [],
    population_cancer_risks: [],
    mutation_probabilities: [
      {"no mutation": {decimal: "", percent: "N/A"}},
      {BRCA1: {decimal: "", percent: "N/A"}},
      {BRCA2: {decimal: "", percent: "N/A"}},
      {PALB2: {decimal: "", percent: "N/A"}},
      {CHEK2: {decimal: "", percent: "N/A"}},
      {ATM: {decimal: "", percent: "N/A"}},
      {BARD1: {decimal: "", percent: "N/A"}},
      {RAD51D: {decimal: "", percent: "N/A"}},
      {RAD51C: {decimal: "", percent: "N/A"}},
      {BRIP1: {decimal: "", percent: "N/A"}}
    ]
  },
  claus: {
    breast: {fiveYear: 'N/A', lifetime: 'N/A'},
    cancer_risks: []
  },
  gail: {
    breast: {fiveYear: 'N/A', lifetime: 'N/A'},
    cancer_risks: []
  },
  tyrer_cuzick: {
    breast: {fiveYear: 'N/A', lifetime: 'N/A'},
    cancer_risks: [],
    mutation_probabilities: [
      {"no mutation": {decimal: "", percent: "N/A"}},
      {BRCA1: {decimal: "", percent: "N/A"}},
      {BRCA2: {decimal: "", percent: "N/A"}}
    ]
  },
  brcapro: {
    breast: {fiveYear: 'N/A', lifetime: 'N/A'},
    ovarian: {fiveYear: 'N/A', lifetime: 'N/A'},
    cancer_risks: [],
    mutation_probabilities: [
      {"no mutation": {decimal: "", percent: "N/A"}},
      {BRCA1: {decimal: "", percent: "N/A"}},
      {BRCA2: {decimal: "", percent: "N/A"}}
    ]
  },
  mmrpro: {
    colon: {fiveYear: 'N/A', lifetime: 'N/A'},
    uterine_endometrial: {fiveYear: 'N/A', lifetime: 'N/A'},
    cancer_risks: [],
    mutation_probabilities: [
      {"no mutation": {decimal: "", percent: "N/A"}},
      {MLH1: {decimal: "", percent: "N/A"}},
      {MSH2: {decimal: "", percent: "N/A"}},
      {MSH6: {decimal: "", percent: "N/A"}}
    ]
  },
  pancpro: {
    pancreatic: {fiveYear: 'N/A', lifetime: 'N/A'},
    cancer_risks: [],
    mutation_probabilities: [
      {"no mutation": {decimal: "", percent: "N/A"}},
      {pancreaticGene: {decimal: "", percent: "N/A"}}
    ]
  },
  melapro: {
    melanoma: {fiveYear: 'N/A', lifetime: 'N/A'},
    cancer_risks: [],
    mutation_probabilities: [
      {"no mutation": {decimal: "", percent: "N/A"}},
      {P16: {decimal: "", percent: "N/A"}}
    ]
  },
  qrisk3: {
    cardiovascular: {tenYear: 'N/A'},
    cancer_risks: []
  },
}

export const risk_factors = {
  risk_factor_id: null,
  generalRiskFactors: {
    heightFeet: '',
    heightInches: '',
    heightMeters: '',
    heightCentimeters: '',
    heightUnitSystem: 'uss',
    weightPounds: '',
    weightKilograms: '',
    weightUnitSystem: 'pounds',
    totalInches: ''
  },
  reproductiveRiskFactors: {
    ageFirstMenstruation: '',
    ageFirstBirth: '',
    postMenopausal: '',
    ageAtMenopause: '',
    takenHRT: '',
    howManyYearsOnHRT: '',
    typeOfHRT: '',
    yearsSinceLastTakenHRT: '',
    yearsOfIntendedUseOfHRT: ''
  },
  proceduresRiskFactors: {
    breastDensityMethod: '',
    biRadsDensity: '',
    percentageDensity: '',
    hadBreastBiopsy: false,
    amountOfBreastBiopsies: '',
    breastBiopsyBenign: false,
    breastBiopsyHyperplasia: false,
    breastBiopsyAtypicalHyperplasia: false,
    breastBiopsyLCIS: false,
    breastBiopsyUnknown: false
  },
  surgeriesRiskFactors: {
    hadHysterectomy: false,
    ageAtHysterectomy: '',
    hadMastectomy: false,
    ageAtMastectomy: '',
    typeOfMastectomy: '',
    hadOophorectomy: false,
    ageAtOophorectomy: '',
    typeOfOophorectomy: '',
    hadSalpingectomy: false,
    ageAtSalpingectomy: '',
    hadTubalLigation: false,
    ageAtTubalLigation: '',
  },
  alcoholRiskFactors: {
    alcohol: false,
    drinks_per_day: ''
  },
  // oral contraceptive
  OCRiskFactors: {
    oral_contraceptive_pill: false,
    ocp_years: '',
    ocp_last_2_years: false
  }
}
