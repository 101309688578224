import sdk from './sdk';
import Cookie from 'js-cookie'
import axios from 'axios';
import logoutAxios from 'axios';

const clearTokens = () => {

  Cookie.remove('famgenix_app_tokens');
  sessionStorage.removeItem('famgenix_app_tokens');
  localStorage.removeItem('famgenix_app_tokens');
}

const login = async (username, password) => {
  try {

    let payload = {
      "username": username,
      "password": password
    }
    let url = process.env.REACT_APP_ROOT_API_URL + '/account/clinician_login/';
    let response = await sdk.request('post',url, payload);

    let data = response.data;
    sdk.tokens_to_cookie(data);
    return data;
  } catch (err) {
    throw err
  }
}

const authenticateEmrUser = async (uuid) =>{
  try{
    let payload ={
      UUID: uuid
    }
    let url = process.env.REACT_APP_ROOT_API_URL + '/account/read_only_login/';
    let response = await sdk.request('post',url, payload);

    let data = response.data;
    sdk.tokens_to_cookie(data);
    return data;
  }catch (err) {
    throw err
  }
}

const logout = () => {

  Cookie.remove('famgenix_app_tokens');
  localStorage.removeItem('famgenix_app_tokens');
  // sessionStorage.removeItem("patient-pedigree-token");
  // sessionStorage.removeItem('query_state'); // onbeforeunload gets triggered when refreshed, so this will also be triggered, have to comment it out
  window.location = '/'

}

const logoutRemoveSessionStorage = () => {
  let success = true;
  let url = process.env.REACT_APP_ROOT_API_URL + '/account/logout/';
  let the_headers = {};
  try {

    let decoded = sdk.tokens_from_cookie();
    let token = decoded.token.accessToken
    the_headers['Authorization'] = `Bearer ${token}`

  } catch (error) {
    success = false;
    console.log(error)
  }
  logoutAxios.post(url, {}, {
    headers: the_headers
  })
  .catch((error) => {

  })
  .then(() => {
    // always executed
    clearTokens()
    window.location = '/'
  });
  sessionStorage.removeItem('query_state');
  return success;
}

const send_password_reset_email = async (username, is_new_account) => {
  try {

    let payload = {
      "username": username,
      "new_account": is_new_account
    }
    let url = process.env.REACT_APP_ROOT_API_URL + '/account/reset_password_email/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}
const check_password_link_expire = async (uid64, token, initial_creation)=> {
  try {

    let payload = {
      "uid64": uid64,
      "token": token,
      "initial_creation": initial_creation
    }
    let url = process.env.REACT_APP_ROOT_API_URL + '/account/check_link_expire/';
    let response = await sdk.request('post', url, payload);
    let data = response.data
    return data;

  } catch (err) {
    console.dir(err)
    throw sdk.parse_error_message(err)
  }
}

const resend_password_reset_email = async (uid64, initial_creation)=>{

  try{
  let payload = {
    "uid64": uid64,
    "initial_creation": initial_creation
  }
    let url = process.env.REACT_APP_ROOT_API_URL + '/account/resend_expired_link/';
    let response = await sdk.request('post', url, payload);

    let data = response.data
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const update_clinician_password = async (uid64, token, new_password, new_password_confirmation, initial_creation, agree_to_terms_and_conditions) => {
  try {

    let payload = {
      "new_password": new_password,
      "new_password_confirmation": new_password_confirmation,
      "uid64": uid64,
      "token": token,
      "initial_creation": initial_creation,
      "agree_to_terms_and_conditions": agree_to_terms_and_conditions
    }
    let url = process.env.REACT_APP_ROOT_API_URL + '/account/reset_password_confirm/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_org_type_set_password = async (payload) => {
  try {

    console.log(payload)
    let url = process.env.REACT_APP_ROOT_API_URL + '/account/get_org_type_set_password/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}


const server_status = async () => {
  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/account/server_status/';
    let response = await sdk.request('get', url);
    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const log_network_data = async (networkData) => {

  let payload ={
    'networkData': networkData
  }

  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/network_activity/log_network_data/';
    let response = await sdk.request('post', url, payload);
    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_api_version = async () => {
  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/account/api_version/';
    let response = await sdk.request('get', url);
    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const login_type_check = async (email, is_emr_login=false) => {
  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/account/login_type/';
    let response = await sdk.request('get', url, {'email': email, 'is_emr_login': is_emr_login});
    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_email_from_username = async (userName) => {
  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/account/get_user_email/';
    let response = await sdk.request('get', url, {'username': userName});
    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}
const saml_login = async (email) => {
  let is_emr_session = sessionStorage.getItem('is_emr_session')
  is_emr_session = is_emr_session == 'true' ? true : false

  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/saml/login/';
    let response = await sdk.request('get', url, {'email': email, 'read_only': is_emr_session});
    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const saml_account_lookup = async (token) => {
  try{

    let response = await axios.get(`${process.env.REACT_APP_ROOT_API_URL}` + '/account/clin_data/', {
      headers: {
        Authorization: 'Bearer ' + token
      }
     })
     sdk.tokens_to_cookie(response.data);
     return response.data

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_oauth_redirect = async (email) => {
  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/oauth/getOAuthURL/';
    let response = await sdk.request('get', url, {'email': email});

    return response.data

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const verify_oauth = async (code, state)=> {
  let is_emr_session = sessionStorage.getItem('is_emr_session')
  is_emr_session = is_emr_session == 'true' ? true : false

  let payload = {
    'code': code,
    'state': state,
    'is_emr': is_emr_session
  }

  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/oauth/verifyCode/';
    let response = await sdk.request('post', url, payload);

    sdk.tokens_to_cookie(response.data);
    return response.data

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_active_broadcasts = async () => {
  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/account/checkBroadcastMessage/';
    let response = await sdk.request('get', url);
    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const check_user_password_set = async (payload) => {
  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/account/check_user_password_set/';
    let response = await sdk.request('get', url, {"uid64": payload.uid64, "token": payload.token});
    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const upload_saml_provider = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/saml/upload_saml_provider/`;
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  }catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const upload_oauth_provider = async (payload) => {
  try {
      
    let url = process.env.REACT_APP_ROOT_API_URL + `/oauth/upload_oauth_provider/`;
    let response = await sdk.request('post', url, payload);
  
    let data = response.data;
    return data;
  }catch (err) {
    throw sdk.parse_error_message(err)
  }
}

export default {
  parse_error_message: sdk ? sdk.parse_error_message : null, //This was implemented because it causes errors on react testing library when testing a component with api calls
  clearTokens,
  login,
  logout,
  logoutRemoveSessionStorage,
  send_password_reset_email,
  update_clinician_password,
  get_org_type_set_password,
  check_password_link_expire,
  resend_password_reset_email,
  authenticateEmrUser,
  server_status,
  log_network_data,
  get_api_version,
  login_type_check,
  saml_login,
  saml_account_lookup,
  get_oauth_redirect,
  verify_oauth,
  get_email_from_username,
  get_active_broadcasts,
  check_user_password_set,
  upload_saml_provider,
  upload_oauth_provider,
}
