import types from "./types";

export const initialize_diseases = (payload) => {
  return {
		type: types.INITIALIZE_DISEASES,
		payload: payload
	 }
}
export const initialize_skip_logics = (payload) => {
	return {
		  type: types.INITIALIZE_SKIP_LOGICS,
		  payload: payload
	   }
  }

export const intitialize_umls = (payload) =>{
	return {
		type: types.INITIALIZE_UMLS,
		payload: payload
	 }
}

export const initialize_genetic_testings = (payload) => {
  return {
		type: types.INITIALIZE_GENETIC_TESTINGS,
		payload: payload
	 }
}

export const initialize_genetic_testing_labs = (payload) => {
	  return {
		type: types.INITIALIZE_GENETIC_TESTING_LABS,
		payload: payload
	 }

}

export const initialize_genetic_testing_panels = (payload) => {
	  return {
		type: types.INITIALIZE_GENETIC_TESTING_PANELS,
		payload: payload
	 }

}

export const initialize_genetic_testings_umls = (payload) =>{
	return {
		type: types.INITIALIZE_GENETIC_TESTINGS_UMLS,
		payload: payload
	 }
}

export const initialize_ancestry_list = (payload) =>{
	return {
		type: types.INITIALIZE_ANCESTRY_LIST,
		payload: payload
	}
}

export const intialize_patients_status = (payload) =>{
	return {
		type: types.INITIALIZE_PATIENT_STATUS,
		payload: payload
	}
}

export const intitialize_surveys = (payload) =>{
	return {
		type: types.INITIALIZE_SURVEYS,
		payload: payload
	 }
}

export const intitialize_disease_categories = (payload) =>{
	return {
		type: types.INITIALIZE_DISEASE_CATEGORIES,
		payload: payload
	 }
}
