import family_api from '../api/family-api';
import * as helper_family_tree from './helper-family-tree'

const create_proband = async (payload, maternal_ancestry, paternal_ancestry) => {
  try {
    let family = await family_api.post_families(payload.clinician_id);

    payload = {
      ...payload,
      family_id: family.id,
      is_proband: true,
      level: 3,
      side: "both",
      ancestry: null
    };

    let proband = await family_api.post_member(payload);
    let proband_parent = await create_proband_parent(family.id, proband, maternal_ancestry, paternal_ancestry)

    let grandparents = await create_proband_grandparent(family.id, proband_parent.father.id, proband_parent.mother.id)
    return {
      ...proband,
      ...proband_parent,
      ...grandparents
    };

  } catch (error) {
    throw error;
  }
}

const create_member = async (level, family_id, gender, parent_side, adopted_out=false, ancestry=null) => {
  try {
    let payload = {
        "family_id": family_id,
        "first_name": "",
        "last_name": "",
        "gender": gender,
        adopted_out,
        "level": level,
        "side": parent_side,
        "ancestry": ancestry
    };
    let member = await family_api.post_member(payload);
    return member;

  } catch (error) {
    throw error;
  }
}

const create_proband_parent = async(family_id, proband, maternal_ancestry, paternal_ancestry) => {
  try {
    let father = await create_member(2, family_id, 'm', 'paternal', proband.father_adopted, paternal_ancestry);
    let mother = await create_member(2, family_id, 'f', 'maternal', proband.mother_adopted, maternal_ancestry);

    let proband_as_child = await create_child(father.id, mother.id, proband.id)

    return {
      father,
      mother
    }

  } catch (error) {
    throw error;
  }
}

const create_child = async(father_id, mother_id, member_id) => {
  try {

    let payload = {
      "father_id": father_id,
      "mother_id": mother_id,
      "member_id": member_id,
    };
    let child = await family_api.post_childs(payload);
    return child;

  } catch (error) {
    throw error;
  }
}


const create_proband_grandparent = async(family_id, father_id, mother_id) => {
  try {
    //Paternal
    let paternal_grandfather = await create_member(1, family_id, 'm', 'paternal')
    let paternal_grandmother = await create_member(1, family_id, 'f', 'paternal')
    let father_as_child = await create_child(paternal_grandfather.id, paternal_grandmother.id, father_id)

    //Maternal
    let maternal_grandfather = await create_member(1, family_id, 'm', 'maternal')
    let maternal_grandmother = await create_member(1, family_id, 'f', 'maternal')
    let mother_as_child = await create_child(maternal_grandfather.id, maternal_grandmother.id, mother_id)

    return {
      paternal_grandfather,
      paternal_grandmother,
      maternal_grandfather,
      maternal_grandmother
    }

  } catch (error) {
    throw error;
  }
}

const create_sibling = async (sibling, family_id, father_id, mother_id) => {
  try {
    let payload = {
      ...sibling,
      family_id,
      level: 3,
      side: "both"
    };
    let member = await family_api.post_member(payload);
    let sibling_as_child = await create_child(father_id, mother_id, member.id)
    return member;

  } catch (error) {
    throw error
  }
}

const create_uncle_aunt = async (uncle_aunt, parent_side, family_id, father_id, mother_id) => {
  try {
    let payload = {
      ...uncle_aunt,
      family_id,
      level: 2,
      side: parent_side,
    };
    let member = await family_api.post_member(payload);
    let uncle_aunt_as_child = await create_child(father_id, mother_id, member.id)
    return member;

  } catch (error) {
    throw error
  }
}

const create_partner = async (spouse, partner, parent_side, is_same_sex) => {
  try {
    let partner_gender = '';
    if(!is_same_sex){
      if(spouse.gender !== null && spouse.gender.toLowerCase() !== 'u'){
        partner_gender = (spouse.gender.toLowerCase() == 'm' ? 'f' : 'm')
      }
      else{
        partner_gender = null;
      }
    }
    else{
      partner_gender = spouse.gender.toLowerCase();
    }


    let payload = {
      first_name: partner.first_name,
      last_name: partner.last_name,
      is_blood_related_to_proband: partner.relationship_data.is_parent_blood_related,
      is_parent_blood_related: partner.relationship_data.is_parent_blood_related,
      gender: partner_gender,

      family_id: spouse.family_id,
      level: spouse.level,
      side: parent_side
    };

    // Save partner as member
    let member = await family_api.post_member(payload);
    let partner_data = Object.assign({}, partner, member)

    let [father_id, mother_id] = helper_family_tree.identify_father_and_mother(spouse, partner_data)

    // Save spouse and partner as parent
    payload = {
      "father_id": father_id,
      "mother_id": mother_id,
      "marital_status": partner.relationship_data.marital_status,
      "is_parent_blood_related": partner.relationship_data.is_parent_blood_related,
      "blood_relation_type": ""
    };
    let relationship_data = await family_api.post_partner_relationship(payload);
    partner_data.relationship_data = Object.assign({}, partner_data.relationship_data, relationship_data)

    return partner_data;

  } catch (error) {
    throw error
  }
}

const update_partner = async (partner) => {
  try {
    let payload = {
      first_name: partner.first_name
    }
    let partner_data = await family_api.patch_member_memberid(partner.id, payload);

    await family_api.patch_partner_relationship_partnerid(partner.relationship_data.id, partner.relationship_data)

    return Object.assign({}, partner, partner_data);
  } catch (error) {
    throw error
  }
}

const create_member_as_child = async (spouse, partner, child, parent_side) => {
  try {

    let [father_id, mother_id] =  helper_family_tree.identify_father_and_mother(spouse, partner)
    let sameSex = (spouse.gender && partner.gender) ?  spouse.gender.toLowerCase() == partner.gender.toLowerCase() : false
    // Add child as member
    let payload = {
        "family_id": spouse.family_id,
        "first_name": child.first_name,
        "last_name": child.last_name,
        "gender": child.gender === null ? null : child.gender.toLowerCase(),
        "pregnancy": 'pregnancy' in child ? child.pregnancy : false,

        "level": spouse.level + 1,
        "side": parent_side,
        // if parents have same sex, then child is adopted in
        "adopted_in": sameSex,

        //-----------------------------------
        // these will not be used anymore, since we catch when adding no_children and infertility
        // nodes with adding fake partner and child to render no_children and infertility nodes
        // leaving these just in case
        "is_no_children_node": child.value === 'no children' || child.value === 'no_children',
        "is_infertility_node": child.value === 'infertility',
        //-----------------------------------

        "is_abortion_node": child.value === 'sab',
        "father_id_check": father_id,
        "mother_id_check": mother_id,
        // "main_parent_to_add_child": partner.id
    };
    let member_data = await family_api.post_member(payload);

    // Add child to parent
    let new_child_data = await create_child(father_id, mother_id, member_data.id)

    member_data.father_id = new_child_data.father_id
    member_data.mother_id = new_child_data.mother_id
    return Object.assign({}, child, member_data)

  } catch (error) {
    console.log(error)
    throw error;
  }
}

const create_parents = async (proband_id, member_id) => {
  try {
    let payload = {
      proband_id, member_id
    };
    let parents = await family_api.post_parents(payload);
    return parents;

  } catch (error) {
    throw error
  }
}

const save_hidden_disease_colors = async (payload) => {
  try {
    let hidden_disease_color = await family_api.save_hidden_disease_colors_post(payload)
    return hidden_disease_color
  }
  catch(error){
    throw error
  }
}

const remove_hidden_disease_colors = async (payload) => {
  try {
    let hidden_disease_color = await family_api.remove_hidden_disease_colors_post(payload)
    return hidden_disease_color
  }
  catch(error){
    throw error
  }
}

const save_family_pedigree_notes = async (payload) => {
  try {
    let data = await family_api.save_family_pedigree_notes(payload)
    return data.family_id
  }
  catch(error){
    throw error
  }
}

export default {
  create_proband,
  create_sibling,
  create_uncle_aunt,
  create_partner,
  update_partner,
  create_member_as_child,
  create_parents,
  save_hidden_disease_colors,
  remove_hidden_disease_colors,
  save_family_pedigree_notes
}
