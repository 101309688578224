import React, { Component } from 'react';
import ReactDropdown from 'react-dropdown';
import Select from "react-select";
import SelectDefault from './control-select-default';

const groupStyles = {
  display: 'flex',
  width: 'fit-content',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = data => (
<div>
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
</div>
);


const ControlSelectGene = (props) => {
  return (
  <div id="gene-filter-select-gene">
    <Select
      id="select-gene-container"
      className="custom-react-select-container"
      classNamePrefix="custom-react-select"
      onChange={(item) => props.onChange(item)}
      value={props.value}
      options = {props.options}
      formatGroupLabel={formatGroupLabel}
      placeholder={props.placeholder}
      isLoading={props.isLoading}
      isDisabled={props.disabled}
      components={props.components}
      inputValue={props.inputValue}
      onInputChange={props.onInputChange}
    />
  </div>
  )
}

export default ControlSelectGene
