export const allCountries = [
  [
    "Argentina",
    "ar",
    "54",
    0
  ],
  [
      "Bahrain",
      "bh",
      "973",
      0
    ],
    [
      "Cocos (Keeling) Islands",
      "cc",
      '61',
      0
    ],
    [
      "Christmas Island",
      "cx",
      "61",
      0
    ],
    [
      "Faroe Islands",
      "fo",
      "298",
      0
    ],
    [
      "Lithuania",
      "lt",
      "370",
      0
    ],
    [
      "Latvia",
      "lv",
      "371",
      0
    ],
    [
      "North Macedonia",
      "mk",
      "389",
      0
    ],
    [
      "Malta",
      "mt",
      "356",
      0
    ],
    [
      "Slovakia",
      "sk",
      "421",
      0
    ],
    [
      "Slovenia",
      "sl",
      "386",
      0
    ],
    [
      "Vatican City",
      "va",
      "39",
      0
    ],
    [
      "Turkey",
      "tr",
      "90",
      0
    ],
    [
      "Taiwan",
      "tw",
      "886",
      0
    ],
    // [
    //   "Bahamas",
    //     "bs",
    //     "1242",
    //     8,
    //     ["242"]
    // ],
    [
      "United Kingdom",
      "gb",
      "44",
      0
    ],
    [
      "Australia",
      "au",
      "61",
      0
    ],
    [
        "United States",
        "us",
        "1",
        0
      ],
    [
      "Austria (Österreich)",
      "at",
      "43"
    ],
    [
      "Belgium (België)",
      "be",
      "32"
    ],
    // [
    //   "Bermuda",
    //   "bm",
    //   "1441",
    //   10,
    //   ["441"]
    // ],
    // [
    //   "Bolivia",
    //   "bo",
    //   "591"
    // ],
    [
      "Brazil (Brasil)",
      "br",
      "55"
    ],
    // [
    //   "British Virgin Islands",
    //   "vg",
    //   "1284",
    //   11,
    //   ["284"]
    // ],
    // [
    //   "Bulgaria (България)",
    //   "bg",
    //   "359"
    // ],
    // [
    //   "Cambodia (កម្ពុជា)",
    //   "kh",
    //   "855"
    // ],
    // [
    //   "Cameroon (Cameroun)",
    //   "cm",
    //   "237"
    // ],
    [
      "Canada",
      "ca",
      "1",
      1,
      ["204", "226", "236", "249", "250", "289", "306", "343", "365", "387", "403", "416", "418", "431", "437", "438", "450", "506", "514", "519", "548", "579", "581", "587", "604", "613", "639", "647", "672", "705", "709", "742", "778", "780", "782", "807", "819", "825", "867", "873", "902", "905"]
    ],
    // [
    //   "Cape Verde (Kabu Verdi)",
    //   "cv",
    //   "238"
    // ],
    // [
    //   "Caribbean Netherlands",
    //   "bq",
    //   "599",
    //   1,
    //   ["3", "4", "7"]
    // ],
    [
      "Chile",
      "cl",
      "56"
    ],
    // [
    //   "China (中国)",
    //   "cn",
    //   "86"
    // ],
    [
      "Colombia",
      "co",
      "57"
    ],
    [
      "Costa Rica",
      "cr",
      "506"
    ],
    [
      "Côte d’Ivoire",
      "ci",
      "225"
    ],
    // [
    //   "Croatia (Hrvatska)",
    //   "hr",
    //   "385"
    // ],
    // [
    //   "Cuba",
    //   "cu",
    //   "53"
    // ],
    [
      "Curaçao",
      "cw",
      "599",
      0
    ],
    [
      "Czech Republic (Česká republika)",
      "cz",
      "420"
    ],
    [
      "Denmark (Danmark)",
      "dk",
      "45"
    ],
    // [
    //   "Ecuador",
    //   "ec",
    //   "593"
    // ],
    // [
    //   "Egypt (‫مصر‬‎)",
    //   "eg",
    //   "20"
    // ],
    // [
    //   "El Salvador",
    //   "sv",
    //   "503"
    // ],
    // [
    //   "Equatorial Guinea (Guinea Ecuatorial)",
    //   "gq",
    //   "240"
    // ],
    // [
    //   "Eritrea",
    //   "er",
    //   "291"
    // ],
    [
      "Estonia (Eesti)",
      "ee",
      "372"
    ],
    // [
    //   "Eswatini",
    //   "sz",
    //   "268"
    // ],
    // [
    //   "Ethiopia",
    //   "et",
    //   "251"
    // ],
    [
      "Fiji",
      "fj",
      "679"
    ],
    [
      "Finland (Suomi)",
      "fi",
      "358",
      0
    ],
    [
      "France",
      "fr",
      "33"
    ],
    // [
    //   "French Guiana (Guyane française)",
    //   "gf",
    //   "594"
    // ],
    // [
    //   "French Polynesia (Polynésie française)",
    //   "pf",
    //   "689"
    // ],
    // [
    //   "Georgia (საქართველო)",
    //   "ge",
    //   "995"
    // ],
    [
      "Germany (Deutschland)",
      "de",
      "49"
    ],
    [
      "Greece (Ελλάδα)",
      "gr",
      "30"
    ],
    // [
    //   "Greenland (Kalaallit Nunaat)",
    //   "gl",
    //   "299"
    // ],
    // [
    //   "Guatemala",
    //   "gt",
    //   "502"
    // ],
    // [
    //   "Haiti",
    //   "ht",
    //   "509"
    // ],
    // [
    //   "Honduras",
    //   "hn",
    //   "504"
    // ],
    [
      "Hong Kong (香港)",
      "hk",
      "852"
    ],
    [
      "Hungary (Magyarország)",
      "hu",
      "36"
    ],
    [
      "Iceland (Ísland)",
      "is",
      "354"
    ],
    // [
    //   "India (भारत)",
    //   "in",
    //   "91"
    // ],
    [
      "Indonesia",
      "id",
      "62"
    ],
    // [
    //   "Iran (‫ایران‬‎)",
    //   "ir",
    //   "98"
    // ],
    // [
    //   "Iraq (‫العراق‬‎)",
    //   "iq",
    //   "964"
    // ],
    [
      "Ireland",
      "ie",
      "353"
    ],
    [
      "Israel (‫ישראל‬‎)",
      "il",
      "972"
    ],
    [
      "Italy (Italia)",
      "it",
      "39",
      0
    ],
    [
      "Japan (日本)",
      "jp",
      "81"
    ],
    // [
    //   "Kazakhstan (Казахстан)",
    //   "kz",
    //   "7",
    //   1,
    //   ["33", "7"]
    // ],
    // [
    //   "Kuwait (‫الكويت‬‎)",
    //   "kw",
    //   "965"
    // ],
    // [
    //   "Lebanon (‫لبنان‬‎)",
    //   "lb",
    //   "961"
    // ],
    [
      "Mexico (México)",
      "mx",
      "52"
    ],
    // [
    //   "Monaco",
    //   "mc",
    //   "377"
    // ],
    // [
    //   "Mongolia (Монгол)",
    //   "mn",
    //   "976"
    // ],

    // [
    //   "Morocco (‫المغرب‬‎)",
    //   "ma",
    //   "212",
    //   0
    // ],
    // [
    //   "Mozambique (Moçambique)",
    //   "mz",
    //   "258"
    // ],
    [
      "Netherlands (Nederland)",
      "nl",
      "31"
    ],
    // [
    //   "New Caledonia (Nouvelle-Calédonie)",
    //   "nc",
    //   "687"
    // ],
    [
      "New Zealand",
      "nz",
      "64"
    ],
    // [
    //   "Nicaragua",
    //   "ni",
    //   "505"
    // ],
    // [
    //   "Nigeria",
    //   "ng",
    //   "234"
    // ],
    // [
    //   "North Korea (조선 민주주의 인민 공화국)",
    //   "kp",
    //   "850"
    // ],
    [
      "Norway (Norge)",
      "no",
      "47",
      0
    ],
    // [
    //   "Pakistan (‫پاکستان‬‎)",
    //   "pk",
    //   "92"
    // ],
    // [
    //   "Panama (Panamá)",
    //   "pa",
    //   "507"
    // ],
    // [
    //   "Paraguay",
    //   "py",
    //   "595"
    // ],
    [
      "Poland (Polska)",
      "pl",
      "48"
    ],
    [
      "Portugal",
      "pt",
      "351"
    ],
    [
      "Romania (România)",
      "ro",
      "40"
    ],
    // [
    //   "Russia (Россия)",
    //   "ru",
    //   "7",
    //   0
    // ],
    // [
    //   "Sierra Leone",
    //   "sl",
    //   "232"
    // ],
    [
      "Singapore",
      "sg",
      "65"
    ],
    [
      "South Africa",
      "za",
      "27"
    ],
    // [
    //   "South Korea (대한민국)",
    //   "kr",
    //   "82"
    // ],
    [
      "Spain (España)",
      "es",
      "34"
    ],

    // [
    //   "Sweden (Sverige)",
    //   "se",
    //   "46"
    // ],
    // [
    //   "Switzerland (Schweiz)",
    //   "ch",
    //   "41"
    // ],
    // [
    //   "Ukraine (Україна)",
    //   "ua",
    //   "380"
    // ],
    [
      "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
      "ae",
      "971"
    ],
    // [
    //   "Uruguay",
    //   "uy",
    //   "598"
    // ],
  ];

  export var iso2 = []
  for (var i = 0; i < allCountries.length; i++) {
    var c = allCountries[i];
    iso2[i] = c[1]
  }

  export var canadaAreaCodes = ["204", "226", "236", "249", "250", "289", "306", "343", "365", "387", "403", "416", "418", "431", "437", "438", "450", "506", "514", "519", "548", "579", "581", "587", "604", "613", "639", "647", "672", "705", "709", "742", "778", "780", "782", "807", "819", "825", "867", "873", "902", "905"]
