import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";

import ErrorSummary from "../error-summary";
import ActivityIndicator from "../activity-indicator";

interface Props {
    name: string;
    label: string;
    translation_key: string;
    title: string;
    onClose: () => void;
    onCategorySave: (categoryInfo: any) => void;
    add_or_edit: string;
}

interface State {
    name: string;
    label: string;
    translation_key: string;
    errorMessages: Array<string>;
    loading: boolean;
}


class CategoryModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            name: '',
            label: '',
            translation_key: '',
            errorMessages: [],
            loading: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        this.setState({
            name: this.props.name,
            label: this.props.label,
            translation_key: this.props.translation_key
        });
    }

    handleChange = (e: any) => {
        this.setState({
            [e.target.name]: e.target.value
        } as any);
    }

    onSave() {
        let categoryInfo = {
            name: this.state.name,
            label: this.state.label,
            translation_key: this.state.translation_key,
            add_or_edit: this.props.add_or_edit
        }

        this.props.onCategorySave(categoryInfo);
    }

    render() {
        return ReactDOM.createPortal(
            <React.Fragment>

                <div
                    style={{ display: "block" }}
                    className="modal"
                    role="dialog"
                >
                    <div
                        onClick={e => e.stopPropagation()}
                        className="modal-dialog modal-xl disease-modal"
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    onClick={() => this.props.onClose()}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <i className="fa fa-close" />
                                </button>
                                <h4 className="modal-title text-white text-capitalize">{this.props.title}</h4>
                            </div>

                            <div className="modal-body">
                                <ErrorSummary
                                    transparent={true}
                                    errorMessages={this.state.errorMessages}
                                />

                                <div className="row">
                                    <div className="col-lg-4 col-md-4">
                                        <label>Category Name</label>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control normal-input-text"
                                                name="name"
                                                onChange={this.handleChange}
                                                value={this.state.name}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <label>Category Label</label>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control normal-input-text"
                                                name="label"
                                                onChange={this.handleChange}
                                                value={this.state.label}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <label>Translation Key</label>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control normal-input-text"
                                                name="translation_key"
                                                onChange={this.handleChange}
                                                value={this.state.translation_key}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={() => this.props.onClose()}
                                    className="btn btn-light-outline no-margin-right "
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={() => this.onSave()}
                                    className="btn btn-dark "
                                    data-toggle="modal"
                                    data-dismiss="modal"
                                >
                                    Save
                                </button>
                            </div>

                            <ActivityIndicator loading={this.state.loading} modal={true} />
                        </div>
                    </div>
                </div>
            </React.Fragment>,
            document.body
        );
    }
}

const redux_state = (state: any) => ({
    history_diseases: state.patient.history_diseases,
    static_list: state.static_list,
    patient: state.patient
});

const redux_actions = (dispatch: any) => ({
    dispatch: (action: any) => dispatch(action)
});

export default connect(
    redux_state,
    redux_actions
)(CategoryModal);
