import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Component that alerts if you click outside of it
 */
export default class OutsideAlerter extends Component {
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && !String(event.target.id).includes('view') && !String(event.target.id).includes('delete') && !String(event.target.className).includes('Dropdown') && !String(event.target.className).includes('react-select')) {
            this.props.action();
        }
    }

    render() {
        return (this.props.clinicianColumn ? <td className="q-clinician" onClick={() => this.props.setCurrentlyActivePatientForClinicianColumn(this.props.patientID)}
        onMouseOver={() => this.props.setCurrentlyHoveredPatientForClinicianColumn(this.props.patientID)}
        onMouseLeave={() => this.props.setCurrentlyHoveredPatientForClinicianColumn('')} ref={this.wrapperRef}>{this.props.children}</td>
        :
        <div style={{display: 'inline'}} ref={this.wrapperRef}>{this.props.children}</div>);
    }
}

OutsideAlerter.propTypes = {
    children: PropTypes.element.isRequired,
};
