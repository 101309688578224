import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";

import SearchTextBox from './search-text-box';
import RecentSearchList from './recent-search-list';
import * as helper_search from '../../helpers/helper-search';

class SearchResultsBar extends Component {
  constructor(props) {
    super(props);
    this.state = {showRecentSearchList: false};

    this.handleSearchTextChange  = this.handleSearchTextChange.bind(this);
    this.handleSearchBoxFocus    = this.handleSearchBoxFocus.bind(this);

    this.handleSearchFilterClicked = this.handleSearchFilterClicked.bind(this);
    this.hideRecentSearchList      = this.hideRecentSearchList.bind(this);
    this.showRecentSearchList      = this.showRecentSearchList.bind(this);

    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.handleDeleteSearchEntry = this.handleDeleteSearchEntry.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside(event) {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.hideRecentSearchList();
    }
  }

  /*SEARCH FORM HANDLERS*/
  handleSearchTextChange(search_text) {
    // update redux search with new search text
    helper_search.updateSearchText(this.props.dispatch, search_text);
    this.hideRecentSearchList();
  }

  handleSearchBoxFocus() {
    this.showRecentSearchList();
  }

  /*RECENT SEARCH LIST HANDLERS*/
  handleSearchFilterClicked(search_text) {
    this.handleSearchTextChange(search_text);
    this.props.queryProbands();
    this.hideRecentSearchList();
  }

  hideRecentSearchList() {
    this.setState({showRecentSearchList: false});
  }

  showRecentSearchList() {
    this.setState({showRecentSearchList: true});
  }

  handleDeleteSearchEntry(search_history) {
    // the entry to delete has been removed, passing in new search history
    helper_search.updateSearchHistory(this.props.dispatch, search_history);
  }

  render() {
    let classes = "search-results-searchBar";

    return (
      <div >

        <SearchTextBox onSearchTextChange={this.handleSearchTextChange}
                       onSearchBoxFocus={this.handleSearchBoxFocus}
                       onSearchSubmit={this.props.handleSearchSubmit}
                       classes={classes}
        />

        <RecentSearchList showRecentSearchList={this.state.showRecentSearchList}
                          onSearchFilterClicked={this.handleSearchFilterClicked}
                          onDeleteSearchEntry={this.handleDeleteSearchEntry}
                          history={this.props.history}
        />

      </div>
    );
  }
}

const redux_state = state => ({
  searchFilter: state.search.searchFilter
});

const redux_actions = dispatch => ({
  dispatch: (action) => dispatch(action)
});

export default connect(redux_state, redux_actions)(SearchResultsBar);
