import sdk from './sdk';

// IE11 needs URL to end with a slash '/'

const search_umls = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/umls/search/';
    let response = await sdk.request('get', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const search_umls_gene = async(payload) =>{
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/umls/gene/';
    let response = await sdk.request('get', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}


const search_umls_panel_genes = async(payload) =>{
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/umls/geneListCheck/';
    let response = await sdk.request('get', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

export default {
  parse_error_message: sdk.parse_error_message,
  search_umls,
  search_umls_gene,
  search_umls_panel_genes
}
