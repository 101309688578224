import React, { Component } from "react";
import ReactDOM from "react-dom";

import ErrorSummary from "./error-summary";
import ActivityIndicator from "./activity-indicator";

class ModalUpdateAges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMessages: [],
            age_increment: 0,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({ age_increment: e.target.value });
    }

    render() {

        return ReactDOM.createPortal(
            <React.Fragment>
                <div
                    style={{ display: "block" }}
                    className="modal"
                    role="dialog"
                >
                    <div
                        onClick={e => e.stopPropagation()}
                        className="modal-dialog modal-l"
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    onClick={() => this.props.onClose()}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <i className="fa fa-close" />
                                </button>
                                <h4 className="modal-title text-white text-capitalize">Update Ages</h4>
                            </div>

                            <div className="modal-body">
                                <ErrorSummary
                                    transparent={true}
                                    errorMessages={this.state.errorMessages}
                                />
                                <p>Ages will be updated according to the year of birth entered for each person. Please enter the number of years to increase the ages of those without a year of birth.</p>

                                <div className="row">
                                    <div className="col-lg-2 col-md-3">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control normal-input-text"
                                                name="age_increment"
                                                onChange={this.handleChange}
                                                value={this.state.age_increment}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={() => this.props.onSaveModalUpdateAges(this.state.age_increment)}
                                    className="btn btn-dark "
                                    data-toggle="modal"
                                    data-dismiss="modal"
                                >
                                    Update
                                </button>
                                <button
                                    type="button"
                                    onClick={() => this.props.onCancel()}
                                    className="btn btn-light-outline no-margin-right "
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </button>

                            </div>

                            <ActivityIndicator loading={this.state.loading} modal={true} />
                        </div>
                    </div>
                </div>
            </React.Fragment>,
            document.body
        );
    }
}

export default ModalUpdateAges;
