import React, { useMemo } from 'react';
import { useStore, useNodes } from '@xyflow/react';

import {
  ClientSideNodeTypes
} from './pedigree-constants';
import { createHook, createLine, getEdgeParams, createUUID } from './utils';

const FloatingMultipleBirthEdge= (props) => {
  const id = props.id;
  const source = props.source;
  const target = props.target;
  const style = props.style;
  const markerEnd = props.markerEnd

  const nodes = useNodes();

  const sourceNode = useMemo(() => nodes.find((n) => n.id === source), [source, nodes]);
  const targetNode = useMemo(() => nodes.find((n) => n.id === target), [target, nodes]);

  // useMemo must be used before a return in a non conditional way
  // targetNode is the Person
  const multipleBirthNodes = useMemo(() => nodes.filter((n) => targetNode && n.id !== target && n.data.profile && n.data.profile.twin_id === targetNode.data.profile.twin_id), [target, targetNode, nodes]);

  if (!sourceNode || !targetNode) {
    return null;
  }

  let { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

  if (Math.abs(sy-ty) < 15) sy = ty;

  let selected_node = Object.values(props.data.datastore.nodes).find(node => node.selected);

  let box = null;

  if (selected_node) {
    box = [selected_node.x, selected_node.y, 150, 150];
  }

  let sourceNodePos = [sx, sy];
  let targetNodePos = [tx, ty];

  let pathLine = null;
  let theID = id + "_zzz";

  //  props.data.datastore.updateEdge({"src":sourceNode.id,"target":targetNode.id, "type":"","edgelist":[theID]});

  if(box && (props.data.datastore.hit(sourceNodePos, box) || props.data.datastore.hit(targetNodePos, box))){
    let lineHeight = 0;

    let inter = props.data.datastore.getIntersection(sourceNode.id,targetNode.id);

    if (inter.length === 0) {
      //There are no nodes between, so make the line straight.
      if (Math.abs(sx-tx) > 20) {
        pathLine = (<line id={theID} x1={sx} y1={sy} x2={tx} y2={ty} style={style}></line>);
      } else {
        let adopted_in = "none";
        let intersection = props.data.datastore.getHorizonalLines(tx,sy,tx, ty);
        let items = [] ;
        let key = createUUID()
        if (intersection.length == 0) {
          theID = id + "_a";
          items.push(<line key={key} id={theID} x1={tx} y1={sy} x2={tx} y2={ty} style={style} strokeDasharray={adopted_in}></line>);
        } else {
          let hooksAndLines = props.data.datastore.splitVerticalLine(tx, sy, ty,intersection);
          let lines = hooksAndLines["lines"];
          for (let i = 0; i < lines.length; i++)
          {
            let line = createLine(lines[i], id, i, adopted_in, style);
            items.push(line);
          }
          let hooks = hooksAndLines["hooks"];
          for (let i = 0; i < hooks.length; i++)
          {
            let pathLine = createHook(hooks[i], id, i, markerEnd, style);
            items.push(pathLine);
          }
        }
        pathLine = items;
      }
    } else {
      const d =`M ${sx} ${sy} ${tx} ${ty}`;
      pathLine = (<path id={theID} className="react-flow__edge-path" d={d} style={style} />);
    }
  }
  else{
    const d =`M ${sx} ${sy} ${tx} ${ty}`;
    pathLine = (<path id={theID} className="react-flow__edge-path" d={d} style={style} />);
  }

  let identical_line = null;
  if (props.data.multiple_birth_type === "identical") {
    // we need to grab all nodes that are in the multiple birth set and
    // draw the identical lines to the midpoint to the closet multiple birth
    // person to the left of the current person
    let leftsideNode = null;
    for (let z=0; z<multipleBirthNodes.length; z++) {
      if (leftsideNode) {
        // leftsideNode was set previously, see if there is a closer node to the left
        if (multipleBirthNodes[z].position.x > leftsideNode.position.x
            && multipleBirthNodes[z].position.x < targetNode.position.x) {
          // need to know the edge sx,sy & tx,ty for multipleBirthNodes[z]
          // so we can calculate the midpoint and make a line from current node
          // midpoint to the multipleBirthNodes[z] node midpoint
          leftsideNode = multipleBirthNodes[z];
        }
      } else {
        // leftsideNode node has not been set yet
        if (multipleBirthNodes[z].position.x < targetNode.position.x) {
          // need to know the edge sx,sy & tx,ty for multipleBirthNodes[z]
          // so we can calculate the midpoint and make a line from current node
          // midpoint to the multipleBirthNodes[z] node midpoint
          leftsideNode = multipleBirthNodes[z];
        }
      }
    }

    if (leftsideNode) {
      const leftsideNodeEdgeParams = getEdgeParams(sourceNode, leftsideNode);

      const midpoint = {x: (sx + tx)/2, y: (sy + ty)/2};
      const leftsideMidpoint = {x: (leftsideNodeEdgeParams.sx + leftsideNodeEdgeParams.tx)/2, y: (leftsideNodeEdgeParams.sy + leftsideNodeEdgeParams.ty)/2};
      identical_line = (<line key={createUUID()} x1={leftsideMidpoint.x} y1={leftsideMidpoint.y} x2={midpoint.x} y2={midpoint.y} style={style}></line>);
    }
  }

  return (
    <g className="react-flow__connection">
    	{pathLine}
      {identical_line}
		  <div stlye="font='italic 40px serif' stroke='blue' fill='red'"> {id}</div>
    </g>
  );
};

export default FloatingMultipleBirthEdge;
