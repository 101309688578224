import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";

import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css';

import ErrorSummary from './error-summary'
import ActivityIndicator from './activity-indicator'

import * as patient_actions from '../store/patient/actions';
import * as model_health_history from '../model-states/m-health-history'
import ModalConfirmDelete from './modal-confirm-delete';

import ModalGeneBody from './gene-form';
import genetic_testing_api from '../api/genetic-testing-api'
import * as static_list_actions from '../store/static_list/actions'
import { cloneDeep, isNaN, set } from "lodash";
import CustomSelectLab from './custom-select-lab'
import { createUUID } from './react-flow-pedigree/utils';
import ModalConfirmPanelChange from './modal-confirm-panel-change';
import ModalEditCustomPanel from './customize-gene-panels';
  const emptyGeneDetail = {
    rkey: '',
    id: '',
    gene: '',
    umls_id: '',
    umls_name: '',
    uuid: '',
    result: '',
    variant: '',
    status: '',
    type: '',
    panel_name: '',
  };

class ModalGeneTest extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      loading_delete: false,
      updateGenePanel: false,
      showChangeTestModal: false,
      showEditCustomPanel: false,
      tempGenes: [],
      tempPanelId: null,
      tempPanelName: '',
      errorMessages: [],
      all_testing_labs: [],
      all_gene_panels: [],
      member_genes: [],
      selectedPanel: '',
      panel_date: null,
      panel_notes: '',
      panel_status : '',
      panel_id: null,
      testing_panel: '',
    }

    this.setGenePanels  = this.setGenePanels.bind(this);
    this.setPanelGenes = this.setPanelGenes.bind(this);
    this.updatepanel_status = this.updatepanel_status.bind(this);
    this.addGene = this.addGene.bind(this);
    this.updateGene = this.updateGene.bind(this);
    this.deleteGene = this.deleteGene.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.setPreviousGene = this.setPreviousGene.bind(this);
    this.getPanelName = this.getPanelName.bind(this);
    this.loadGenePanelsFromAPI = this.loadGenePanelsFromAPI.bind(this);
    this.orderGenes = this.orderGenes.bind(this);
    this.sortGenePanel = this.sortGenePanel.bind(this);
    this.isNewGene = this.isNewGene.bind(this);
    this.previousGeneCheck = this.previousGeneCheck.bind(this);
    this.cancelPanelChange = this.cancelPanelChange.bind(this);
    this.confirmPanelChange = this.confirmPanelChange.bind(this);
    this.openEditCustomPanel = this.openEditCustomPanel.bind(this);
  }

  async componentDidMount() {
    await this.loadGenePanelsFromAPI();
    const { geneDetail } = this.props;

    if (geneDetail?.member_genes) {
        this.setPreviousGene(geneDetail);
    } else if (geneDetail) {
        this.setState({ member_genes: [geneDetail] });
    }

    this.setGenePanels();
    this.mounted = true;

    window.addEventListener('query-umls', this.queryUMLS, false);
    window.addEventListener('query-more-umls', this.queryMoreUMLS, false);
}

  async componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('query-umls', this.queryUMLS, false);
    window.removeEventListener('query-more-umls', this.queryMoreUMLS, false);
  }
  openEditCustomPanel(){
    this.props.openEditCustomPanel()
  }
  cancelPanelChange() {
    this.setState({
      showChangeTestModal: false,
      selectedPanel: '', 
      panel_id: null, 
      panel_status: '', 
      panel_date: '',
      panel_notes: '',
    })

  }
  async confirmPanelChange() {
    try{
      let payload = {
        testing_panel: this.state.testing_panel,
        member_id: this.props.profile.id
      }

      await genetic_testing_api.delete_member_gene_panel(payload)
      if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
        let genePanels = cloneDeep(this.props.getPedigreeData().getGenePanels(this.props.patientRkey));
        let profile_proband = cloneDeep(this.props.getPedigreeData().getProfile(this.props.patientRkey));
        let indexToDelete = genePanels.findIndex(panel => panel.testing_panel === this.state.testing_panel);
        if (indexToDelete !== -1) {
            genePanels.splice(indexToDelete, 1);
        }
        await this.props.getPedigreeData().setGenePanels(this.props.patientRkey, genePanels);
        await this.props.getPedigreeData().setProfile(this.props.patientRkey, profile_proband);
        
      }
    }catch(err){
      console.log(err)
    }

    this.setState({
      selectedPanel: this.state.tempPanelName, 
      panel_id: this.state.tempPanelId, 
      member_genes: this.state.tempGenes,
      tempGenes: [],
      tempPanelId: null,
      tempPanelName: '',
      showChangeTestModal: false
    })
  }
  setGenePanels() {
    let genePanels = this.props.static_list.genetic_testin_panels
    const sortedGenePanels = this.sortGenePanel(genePanels)
    let geneLabs = this.props.static_list.genetic_testings_labs
    this.setState({all_gene_panels: sortedGenePanels, all_testing_labs: geneLabs})
  }

  previousGeneCheck(panelName, panel_id, genes) {
    let previousGeneCheck = this.state.member_genes || []
    if(previousGeneCheck.length > 0) {
      this.setState({
        showChangeTestModal: true,
        tempGenes: genes,
        tempPanelId: panel_id,
        tempPanelName: panelName
      })
    }
    else{
      this.setPanelGenes(panelName, panel_id, genes)
    }
  }

  setPanelGenes(panelName, panel_id, genes){
    if(genes === undefined || genes === null){
      let gene_detail = model_health_history.createHistoryGene()
      this.setState({ selectedPanel: '', member_genes: [gene_detail]})
    }else{
    this.setState({selectedPanel: panelName, panel_id: panel_id , member_genes: genes})
    }
  }

  sortGenePanel(panels) {
    Object.keys(panels).forEach(panelKey => {
      panels[panelKey].genes.sort((a, b) => {
        if (a.gene < b.gene) {
          return -1;
        }
        if (a.gene > b.gene) {
          return 1;
        }
        return 0;
      });
    });
    
    return panels;
  }
  getPanelName(panelId) {
    const panels = this.props.static_list.genetic_testin_panels;
    if (!panels) return panelId;
    const panel = panels[panelId];
    return panel?.panel_name || panelId;
  }

  async loadGenePanelsFromAPI() {
    if(this.props.static_list.genetic_testin_panels === undefined || this.props.static_list.genetic_testin_panels === null){
      try{
        let labs = await genetic_testing_api.get_genetic_testing_labs({clinician_id: this.props.user.clinician_id})
        let panels = await genetic_testing_api.get_gene_panels({clinician_id: this.props.user.clinician_id})
        const sorted_panels = this.sortGenePanel(panels)
        await this.props.dispatch(static_list_actions.initialize_genetic_testing_panels(sorted_panels));
        await this.props.dispatch(static_list_actions.initialize_genetic_testing_labs(labs));
      } catch (error) {
        console.log(error);
      }
    }
  }

  setPreviousGene(geneDetail) {

    let panel_id = geneDetail.panel_id ? geneDetail.panel_id : geneDetail.panel_name ? geneDetail.panel_name : ''
    let panelName = this.getPanelName(panel_id)
    let panel_status =  geneDetail.panel_status || ''
    let panel_date = geneDetail.panel_date || ''
    let panel_notes = geneDetail.panel_notes || ''
    let member_genes = geneDetail.member_genes || []
    let testing_panel = geneDetail.testing_panel || ''
    
    let ordered_genes = this.orderGenes(member_genes)
    
    this.setState({
      selectedPanel: panelName, 
      panel_id: panel_id, 
      panel_status: panel_status, 
      panel_date: panel_date,
      panel_notes: panel_notes,
      member_genes: ordered_genes,
      testing_panel: testing_panel

    })
  }

  updatepanel_status(status) {
    if (status === 'completed') {
      const updatedGenePanel = this.state.member_genes.map(gene => {
        if (gene.result === undefined || gene.result === null || gene.result === '') {
          return { ...gene, result: 'n' , uuid: createUUID() };
        }
        return gene;
      });
      this.setState({ member_genes: updatedGenePanel, panel_status: status });

    }else{
      this.setState({ panel_status: status });
    }
    
  }
  updateGenepanel_date(date) {
    this.setState({ panel_date: date });
  }

  addGene() {
    let genePanel = [...this.state.member_genes]
    let newGene = {...emptyGeneDetail, uuid: createUUID()}
    genePanel.push(newGene)
    this.setState({member_genes: genePanel})
  }


  updateGene(item, index) {
    let previousPanel = this.state.testing_panel ? this.state.testing_panel : ''
    let genePanel = [...this.state.member_genes]
    // genePanel[index] = {... genePanel[index], ...item, uuid: createUUID(), testing_panel: previousPanel}
    // Note: re-using the uuid the component wwas created with prevents issues down stream like losign focus after saving
    genePanel[index] = {... genePanel[index], ...item, testing_panel: previousPanel}
    this.setState({member_genes: genePanel})
  }

  async deleteGene(index) {
    let genePanel = [...this.state.member_genes]
    let gene = genePanel[index]
    let updateGenes = genePanel.splice(index, 1)
    try{
      this.setState({ errorMessages: [], loading_delete: true })

      if (gene && this.state.testing_panel) {
        await genetic_testing_api.delete_member_genetic_testing_id(gene.id)
        if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
          let genePanels = cloneDeep(this.props.getPedigreeData().getGenePanels(this.props.patientRkey))
          let profile_proband = cloneDeep(this.props.getPedigreeData().getProfile(this.props.patientRkey))
          let indexUpdate = genePanels.findIndex(panel => panel.testing_panel === this.state.testing_panel)
          genePanels[indexUpdate].member_genes = updateGenes
          profile_proband.gene_panels = genePanels;
          await this.props.getPedigreeData().setGenePanels(this.props.patientRkey, genePanels)
          await this.props.getPedigreeData().setProfile(this.props.patientRkey, profile_proband)
        }

      }
      } catch (error) {
        this.setState({ errorMessages: [error.message] })
      } finally {
        this.setState({ loading_delete: false })
      }
    this.setState({member_genes: genePanel})
  }

  isGeneFromUMLS(gene_id, member_genes) {
    if(!gene_id){
      return member_genes.find(gene => gene.umls_id !== null) ? true : false
    }
    gene_id = gene_id + "";
    const is_from_umls = gene_id.substr(0,1) === "C";
    return is_from_umls
  }


  isNewGene(member_genes) {
    return member_genes.every(obj => obj.id === undefined || obj.id === null || obj.id === '');
  }

  validate() {
    let errorMessages = []
    if (this.state.gene_id === null) {
      errorMessages.push('Gene is required')
    }


    this.setState({ errorMessages })
    return errorMessages.length == 0
  }

  async onClickSave() {
    try {
      this.setState({ errorMessages: [], loading: true })

      let isValid = this.validate()
      if (!isValid) return

      // Save member genetic testing to API
      let gene_payload = {};
      let member_genes = this.state.member_genes
      const is_from_umls = this.isGeneFromUMLS(this.state.gene_id, this.state.member_genes);
      const panel_id = this.state.panel_id || ''
      const is_gene_panel = panel_id ? true : false
      const is_multi_genes = (this.state.testing_panel || (member_genes && member_genes.length > 1)) ? true : false
      const singleGeneToPanel = member_genes?.length > 1 && member_genes.some(gene => gene.id);
    if(is_gene_panel){
      gene_payload={
        member_id: this.props.profile.id,
        member_genes: member_genes,
        panel_name: this.state.selectedPanel,
        panel_date: this.state.panel_date,
        panel_status: this.state.panel_status,
        panel_notes: this.state.panel_notes,
        panel_id: panel_id,
        testing_panel: this.state.testing_panel
      }
    }else if(is_multi_genes){
      gene_payload = {
        member_id: this.props.profile.id,
        member_genes: member_genes,
        panel_name: this.state.selectedPanel,
        panel_date: this.state.panel_date,
        panel_status: this.state.panel_status,
        panel_notes: this.state.panel_notes,
        panel_id: this.state.panel_id,
        testing_panel: this.state.testing_panel
      }
    }

    else {
      gene_payload = {
        member_id: this.props.profile.id,
        ...this.state.member_genes[0]
      }
    }

      let new_gene = null;
      var data =  null;
      let is_new_gene = this.isNewGene(this.state.member_genes)

      if(is_new_gene) {
        data = await genetic_testing_api.post_member_genetic_testing(gene_payload)
        new_gene = true
      } else {
        data = await genetic_testing_api.patch_member_genetic_testing_id(gene_payload)
        new_gene = false
      }
      // Save to Redux
      let history_gene_detail = {}
      if(is_gene_panel || is_multi_genes || singleGeneToPanel){
        history_gene_detail={
          member_id: this.props.profile.id,
          member_genes: [...data.member_genes],
          panel_name: this.state.selectedPanel,
          panel_date: this.state.panel_date,
          panel_status: this.state.panel_status,
          panel_notes: this.state.panel_notes,
          panel_id: this.state.panel_id,
          testing_panel: data.id
      }
    }
      else if(is_from_umls || this.state.gene_id =='unsure'){
        history_gene_detail={
          ...data,
        }
      }else{
        history_gene_detail={
          id: data.id || null,
          member_id: this.props.profile.id,
          gene: this.state.gene,
          gene_id: this.state.gene_id,
          result: this.state.result,
          variant: this.state.variant,
          status: this.state.status,
          type: this.state.type,
        }
      }

      let genetics_payload = { ownerRkey: this.props.patientRkey, history_gene_detail };
      this.props.dispatch(patient_actions.save_history_gene_detail(genetics_payload));
      // ***** TODO: account for umls genes
      if (this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null) {
        let genePanels = cloneDeep(this.props.getPedigreeData().getGenePanels(this.props.patientRkey));
        let profile_proband = cloneDeep(this.props.getPedigreeData().getProfile(this.props.patientRkey));
      
        if ((is_multi_genes || panel_id) && new_gene) {
          let panelGenes = cloneDeep(history_gene_detail);
          genePanels.push(panelGenes);
          profile_proband.gene_panels = genePanels;
        
          await this.props.getPedigreeData().setGenePanels(this.props.patientRkey, genePanels);
          await this.props.getPedigreeData().setProfile(this.props.patientRkey, profile_proband);
        } else if (singleGeneToPanel) {
          let genetic_testing = cloneDeep(this.props.getPedigreeData().getGenes(this.props.patientRkey));
          let prevSingleGene = member_genes.find(gene => gene.id);
        
          let genes = genetic_testing.filter(gene => gene.id !== prevSingleGene.id);
        
          let updatedGenes = genePanels.map(panel => (
            history_gene_detail.testing_panel === panel.testing_panel ? history_gene_detail : panel
          ));

          profile_proband.genetic_testing = genes;
          profile_proband.gene_panels = updatedGenes;
        
          await this.props.getPedigreeData().setGenes(this.props.patientRkey, genes);
          await this.props.getPedigreeData().setGenePanels(this.props.patientRkey, updatedGenes);
          await this.props.getPedigreeData().setProfile(this.props.patientRkey, profile_proband);
        } else if (!(is_multi_genes || panel_id) && new_gene) {
          let genes = cloneDeep(this.props.getPedigreeData().getGenes(this.props.patientRkey));
          genes.push(history_gene_detail);
          profile_proband.genetic_testing = genes;

          await this.props.getPedigreeData().setGenes(this.props.patientRkey, genes);
          await this.props.getPedigreeData().setProfile(this.props.patientRkey, profile_proband);
        } else if ((is_multi_genes || panel_id) && !new_gene) {
          let updatedGenes = genePanels.map(panel => (
            history_gene_detail.testing_panel === panel.testing_panel ? history_gene_detail : panel
          ));

          profile_proband.gene_panels = updatedGenes;
        
          await this.props.getPedigreeData().setGenePanels(this.props.patientRkey, updatedGenes);
          await this.props.getPedigreeData().setProfile(this.props.patientRkey, profile_proband);
        } else {
          let genes = cloneDeep(this.props.getPedigreeData().getGenes(this.props.patientRkey));
        
          for (let gene of genes) {
            if (gene.id === history_gene_detail.id) {
              Object.assign(gene, {
                gene: history_gene_detail.gene,
                gene_id: history_gene_detail.gene_id,
                gene_id_id: history_gene_detail.gene_id,
                member_id_id: history_gene_detail.member_id,
                result: history_gene_detail.result,
                rkey: history_gene_detail.rkey,
                status: history_gene_detail.status,
                type: history_gene_detail.type,
                umls_id: history_gene_detail.umls_id,
                umls_name: history_gene_detail.umls_name,
                variant: history_gene_detail.variant,
              });
            }
          }
        
          profile_proband.genetic_testing = genes;
        
          await this.props.getPedigreeData().setGenes(this.props.patientRkey, genes);
          await this.props.getPedigreeData().setProfile(this.props.patientRkey, profile_proband);
        }
      }

      this.props.onSave({rkey: this.props.patientRkey, gene: history_gene_detail})
    } catch (error) {
      this.setState({ errorMessages: [error.message] })
    } finally {
      this.setState({ loading: false })
    }
  }

  orderGenes(genes) {
    if(!genes) return []
    return genes.sort((a, b) => {
      if (a.umls_name < b.umls_name) {
          return -1;
      }
      if (a.umls_name > b.umls_name) {
          return 1;
      }
      return 0;
    });
  }

  render() {
    let displayGenes = this.state.member_genes || []
    let panel_id = this.state.panel_id ? this.state.panel_id : ''

    return ReactDOM.createPortal(
      <React.Fragment>

          <div style={{ display: 'block'}} className="modal fade in" role="dialog">
            <div onClick={(e) => e.stopPropagation()} className="modal-dialog modal-xl" role="document">

            <div className="modal-content">
              <div className="modal-header">
                <button
                  onClick={() => this.props.onClose()}
                  type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="fa fa-close"></i>
                </button>
                <h4 className="modal-title text-white text-capitalize">{this.props.title}</h4>
              </div>

              <div className="modal-body" style={{overflow: 'auto', maxHeight: "75vh"}}>

                <ErrorSummary
                  transparent={true}
                  errorMessages={this.state.errorMessages}
                />
                <div>
                 <div className="row gene-modal-lab">
                  <div className="col-md-11">
                    <CustomSelectLab
                      activePanel={this.state.selectedPanel}
                      panel_date={this.state.panel_date}
                      panel_notes={this.state.panel_notes}
                      panel_status={this.state.panel_status}
                      panel_id={panel_id}
                      allLabs={this.state.all_testing_labs}
                      allPanels={this.state.all_gene_panels}
                      onSelectGenePanel={(panel_name, panel_id ,gene_list)=>this.previousGeneCheck(panel_name, panel_id, gene_list)}
                      onEditCustomPanel={()=>this.openEditCustomPanel()}
                      onPanelStatusUpdated={(status)=>this.updatepanel_status(status)}
                      onPanelDateUpdated={(date)=> this.setState({ panel_date: date })}
                      onPanelNotesUpdated={(notes)=>this.setState({panel_notes: notes})}
                    />
                    </div>
                </div>

                {displayGenes.map((geneInfo, index) => {
                  const geneClass = index > 0 ? 'gene-row' : 'first-gene-row';
                  return (
                    <div className={`row gene-panel-row`} key={geneInfo.uuid}>
                      <div className={`col-md-11 ${geneClass}`}>
                        <ModalGeneBody
                          key={geneInfo.id ? geneInfo.id : geneInfo.uuid}
                          index={index}
                          patientRkey={this.props.patientRkey}
                          profile={this.props.profile}
                          getPedigreeData={this.props.getPedigreeData}
                          updateGene={(gene) => this.updateGene(gene, index)}
                          onTextChange={(gene) => this.updateGene(gene, index)}
                          onDelete={() => this.deleteGene(index)}
                          geneDetail={geneInfo}
                          selectedPanel={this.state.selectedPanel}
                        />
                      </div>
                    </div>
                  );
                })}
                <button type="button"
                  onClick={() => this.addGene()}
                  className="btn btn-teal btn-xs add-gene" data-dismiss="modal">Add Gene</button>
                </div>
              
              </div>

              <div className="modal-footer">
                <button type="button"
                  onClick={() => this.props.onCancel()}
                  className="btn btn-light-outline no-margin-right" data-dismiss="modal">Cancel</button>
                <button type="button"
                  onClick={() => this.onClickSave()}
                  className="btn btn-dark " data-toggle="modal" data-dismiss="modal">Save</button>

                <ActivityIndicator loading={this.state.loading} modal={true} />
              </div>    
            </div>
          </div>
        </div>
        {this.state.showChangeTestModal && 
          <ModalConfirmPanelChange 
            onCancel={()=>this.cancelPanelChange()}
            onOk={()=> this.confirmPanelChange()}
            isOpen={this.state.showChangeTestModal}
            message='Are you sure you want to change the test? Some of the genes entered are not part of the new test. If you continue, those genes will be removed.'
            title='Change Test'
          />
        }
      </React.Fragment>
      ,
      document.body
    );
  }

}

const redux_state = state => ({
  // patient: state.patient
  static_list: state.static_list
});

const redux_actions = dispatch => ({
  dispatch: (action) => dispatch(action)
});

export default connect(redux_state, redux_actions)(ModalGeneTest);
