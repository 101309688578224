import React, { Component } from "react";

import LabelField from './label-field';
import DatePicker from "react-date-picker";

class DateField extends Component {
  constructor(props) {
    super(props);

    this.state = {value: ''};

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidMount() {
    if (this.props.answer?.answer) {
      let date = new Date(this.props.answer.answer);
      const timeOffset = date.getTimezoneOffset() * 60000;
      const newDate = new Date(date.getTime() + timeOffset);
      this.setState({ value: newDate });
    }
  }

  onChange(event) {
    this.setState({ value: event });
  }
  onBlur(event) {
    const answers = (this.props.answer) ? [this.props.answer.answer] : ["", null, undefined];
    let answer = this.state.value;
    if (!answer) {
      return;
    }
    let dateObject = new Date(answer);
    const dateString = dateObject.toISOString().split('T')[0];
    if ( !answers.includes(dateString) ) {
      const payload = {
        field_type: this.props.masterQuestion.type,
        master_question_id: this.props.masterQuestion.id,
        master_question_choice_id: null,
        answer: dateString
      };

      this.props.saveCompletedSurveyAnswer(payload);
    }
  }

  render() {
    let column_one = null;
    let column_two = null;
    let minDate = new Date("1800-01-01" + "T12:00:00");
    let maxDate = new Date("9999-01-01" + "T12:00:00");

    const field = (
      <DatePicker
        name={this.props.masterQuestion.question_uuid}
        id={this.props.masterQuestion.question_uuid}
        onChange={this.onChange}
        value={this.state.value}
        onBlur={this.onBlur}
        className="form-control"
        clearIcon={null}
        dayPlaceholder={"dd"}
        monthPlaceholder={"mm"}
        yearPlaceholder={"yyyy"}
        minDate={minDate}
        maxDate={maxDate}
      />
    );

    if (this.props.masterQuestion.question_label) {

      column_one = (
        <LabelField
          masterQuestion={this.props.masterQuestion}
        />
      );

    }

    column_two = field;

    return (
      <div className="form-group">
        <div className="row">

          <div className="col-md-6">
            {column_one}
          </div>

          <div className="col-md-6">
            {column_two}
          </div>

        </div>
      </div>
    );

  }
}

export default DateField;